@use '@angular/material' as mat;
@import
  'style/base/typography',
  'style/abstracts/variables',
  'style/abstracts/mixins',
  'style/abstracts/functions';

.library {
  text-align: left;
  border-radius: $card-border-radius;

  h2 {
    font-weight: 400;
    margin-bottom: 8px;

    mat-icon {
      margin-right: 8px;
    }
  }

  .mat-divider {
    margin: 0;
  }
}

.library__header {
  .info-block {
    background-color: transparent;
    padding: 0 0 16px;
    font-size: mat.m2-font-size($wise-typography, 'subtitle-2');
  }
}

.library__content {
  .content-block {
    background-color: transparent;
    min-height: 100px;
  }

  .mat-mdc-paginator {
    background: transparent;
  }

  .mat-mdc-paginator-range-label {
    margin: 0 8px;
  }
}

.library__list {
  padding: 8px 0;
}
